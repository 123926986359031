body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
}

a {
  text-decoration: none;
}

.tagStyle {
  padding: 3px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  text-align: center;
  color: #000;
  background-color: #f3f3f3;
}

.sectionTitle {
  margin-bottom: 3px;
  font-weight: 700;
  margin-left: 4px;
  font-size: 12px;
}

.iconBackgroundBg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.fieldLabel {
  font-size: 14px;
  font-weight: bold;
}

.wFull {
  width: 100%;
}

.wHalf {
  width: 50%;
}

.mtLarge {
  margin-top: 30px;
}

.mbLarge {
  margin-bottom: 30px;
}

.mlLarge {
  margin-left: 30px;
}

.mrLarge {
  margin-right: 30px;
}

.mbZero {
  margin-bottom: 0px !important;
}

.mbFive {
  margin-bottom: 5px !important;
}

.customAntInput {
  border-radius: 22px !important;
  height: 50px;
}

.custom-ant-button {
  height: 50px !important;
}

.importantIcon {
  object-fit: contain;
  width: 10px;
  height: 17px;
  margin-right: 3px;
}

.dfaCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.dfsBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dGridCenter {
  display: grid;
  place-items: center;
}

.iconBackgroundBg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.addBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addBoxWhite {
  display: grid;
  place-items: center;
  width: 170px;
  height: 40px;
  color: black;
  background-color: #fff;
}

.activeTopNavLi {
  border-bottom: #3ab44d 3px solid;
}
/* 
.ant-table-row {
  background-color: white;
}

.ant-table-row {
  background-color: white;
}

.ant-table-cell {
  background-color: white !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.viewDocs .ant-modal-content {
  width: 520px !important;
  border-radius: 23px !important;
}

.editEmployer .ant-modal-content {
  width: 620px !important;
  border-radius: 10px !important;
}

.ant-upload.ant-upload-drag {
  border-radius: 20px !important;
}

.modalCloseIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
}

.ant-modal-close {
  display: none !important;
}

/* .ant-pagination.ant-pagination-mini .ant-pagination-item {
  border-radius: 50%;
  background-color: #3ab44d;
}

.ant-pagination-item-active a {
  color: white !important;
} */
/* 
.ant-radio-wrapper-checked span {
  color: #3ab44d !important;
  font-weight: 500;
}

.ant-divider-horizontal {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.ant-picker-input,
.ant-picker.ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}
.ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 10px;
  color: #3ab44d;
}

.ant-select .ant-select-selector {
  border-radius: 10px !important;
}

.ant-select-arrow {
  color: #3ab44d !important;
}

.support .ant-select-selection-placeholder {
  font-size: 14px !important;
}

/* Ant table css table customization starts from here*/

/* .ant-table-cell {
  font-size: 13px !important;
}

.dashBoardTable .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.inpatientDetail .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
  font-size: 13px !important;
  cursor: auto;
}

.ant-table-cell {
  font-size: 12px !important;
} */

/* .dashBoardTable,
.ant-table-cell {
  cursor: auto;
}

.employeeDetailTable .ant-table-cell {
  cursor: auto;
} */ 
/* 
.ant-table-cell {
  cursor: pointer;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
  flex: inherit;
}

/* Ant table css table customization over here*/

/* .inpatientDetail .ant-input {
  background-color: #f8f8f8; */
/* } */ 

/* .ant-pagination-options {
  display: none !important;
} */

/* .anticon-delete svg {
  color: red;
  width: 1rem !important;
  height: 1rem !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.ant-btn-primary:disabled {
  background-color: #c6e8c9;
  color: white;
  border: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #f8f8f8 !important;
}

.ant-form-item-explain-error {
  margin-left: 10px;
}

.ant-modal-confirm-btns .ant-btn-default {
  color: black !important;
}

.ant-modal-confirm-btns .ant-btn-default:hover {
  border-color: #3ab44d !important;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: #3ab44d !important;
}

@media print {
  .printButton {
    display: none !important;
  }
} */


body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

::-moz-selection {
  /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: rgba(58, 180, 67, 1);
  background: rgba(58, 180, 67, 0.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

a {
  text-decoration: none;
  color: #333;
  transition: opacity 0.15s ease-in-out;
}

a:hover {
  opacity: 50%;
  color: #333;
  transition: opacity 0.15s ease-in-out;
}

.alt-a {
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  color: #444 !important;
  transition: all 0.15s ease-in-out;
}

.alt-a:hover {
  opacity: 75%;
  font-weight: 600;
  /* font-size: 16px; */
  color: #000 !important;
  /* color: #f87d4e !important; */
  transition: all 0.15s ease-in-out;
}


.tableContainer {
  background: #FFFFFF;
  box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
  border-radius: 20px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  padding-bottom: 75px
}

.tableText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #404040;
  padding: 10px 0px;
}

.tableSearchBox {
  margin-top: -10px;
  width: 375px;
  height: 46px;
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 15px;
}

.searchText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #A3A3A3;

}

.tableTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #404040;

}

.tableText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #262626;
  margin-bottom: -10px;
  margin-top: -10px;
}

.tableStatus {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  margin-top: -10px;
  margin-bottom: -10px;
  color: #3AB44D;
}

.tableAction {
  margin-top: -15px;
  margin-bottom: -15px;
  height: 32px;
  border-radius: 50px;
  padding: 0px 15px
}

.ant-table-thead>tr>th {
  background-color: white !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
  padding-bottom: 6px !important;
}

.ant-table-cell {
  font-size: 13px !important;

}

#paymentQueueTable .ant-table-cell {
  padding: 12px !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
  padding-bottom: 10px !important;
}

.ant-table-thead .ant-table-cell {
  font-size: 13px !important;
  cursor: auto;
}

.ant-table-cell {
  font-size: 12px !important;
}

.ant-table-cell {
  cursor: pointer;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
  flex: inherit;
}

.ant-select .ant-select-selector {
  border-radius: 14px !important;
  padding: 3px 10px !important;
  height: 32px !important;
}

.ant-select-selector:has(#otp) {
  border-radius: 20px !important;
  /* height: 52px !important; */
  padding: 6px 17px !important;
  height: 46px !important;
}

.ant-select-selector:has(#select42px) {
  border-radius: 12px !important;
  padding: 5px 17px !important;
  height: 36px !important;
}

.ant-select-selector:has(#select42px)>.ant-select-selection-placeholder {
  margin-top: -5px;
}

.ant-select:has(#select42px)>.ant-select-arrow {
  margin-right: 5px !important;
  margin-top: -6px !important;

}

.ant-select-selector:has(#multiple-select42px) {
  border-radius: 20px !important;
  min-height: 36px !important;
  height: auto !important;
}


.ant-select:has(#multiple-select42px)>.ant-select-arrow {
  margin-right: 5px !important;
  margin-top: -8px !important;
}




.ant-select-selector:has(#roleSelect) {
  border: 1px solid #A3A3A3 !important;
  border-radius: 25px !important;
  /* height: 52px !important; */
  padding: 10px 25px !important;
  margin-top: -10px;
  height: 52px !important;
}

.ant-select:has(#roleSelect) .ant-select-arrow {
  margin-top: -10px !important;
  margin-right: 10px;
}

.ant-select-dropdown {
  border-radius: 17px;
}

.ant-select-item-option {
  border-radius: 10px !important;
}

.ant-collapse-header {
  padding: 5px !important;
}

.ant-collapse-header-text {
  height: 25px;

}

.ant-modal-content {
  border-radius: 25px !important;
  padding: 5px 35px !important;
  padding-bottom: 20px !important;
}

.ant-modal-content:has(#docModal) {
  border-radius: 25px !important;
  /* padding: 25px 50px !important; */
  background-color: white;
}

.ant-modal-content:has(#docModalDone) {
  border-radius: 25px !important;
  /* padding: 25px 50px !important; */
  padding: 0px !important;
  background-color: white;
  width: 540px;
}

.ant-modal-content:has(#no-padding-modal) {
  padding: 0px !important;
}

.ant-form-item-explain-error {
  margin-left: 15px;
}

.ant-radio-button-wrapper:where(.css-dev-only-do-not-override-16qhuaf).ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.ant-table-filter-dropdown {
  border-radius: 15px !important;
}

span:has(#dragger)>.ant-upload-list {
  width: 125px !important;
}

span:has(#dragger)>.ant-upload {
  height: 36px !important;
  border-radius: 12px !important;
}

.ant-upload.ant-upload-drag {
  border-radius: 20px !important;
}

.ant-table-cell {
  background-color: white !important;
}

#coloredTable .ant-table-cell {
  background-color: #fafafa !important;
}

#autoComplete .ant-select-selector {
  height: 46px;
  border-radius: 20px !important;
  padding: 7px 15px;
}

.ant-picker-panel-container {
  border-radius: 20px !important;
}

#autoComplete .ant-select-selection-search-input {
  padding: 5px;
  padding-top: 18px;
}

.input {
  border: 1px solid #A3A3A3 !important;
  border-radius: 25px !important;
  /* height: 52px !important; */
  padding: 15px 25px !important;
  margin-top: -10px;
}

.headerText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #171717;
  margin-top: 15px;
}

.breadCrumb1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Black/100% */

  color: #1C1C1C;
  opacity: 70%;
}

.breadCrumb2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #1C1C1C;
}

.ant-select-selector:has(#autoComplete) {
  height: 42px !important;
  border-radius: 18px !important;
  font-size: 14px !important;
}

.ant-select-selection-placeholder {
  line-height: 34px !important;
}

.cardShadow {
  background: #FFFFFF;
  box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.1);
  border-radius: 20px;
  padding: 25px;
  transition: all 0.25s ease-in-out;
  position: relative;
}

.ant-popover-inner {
  padding: 12px !important;
  border-radius: 25px !important;
  box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.2) !important;
}

.ant-select-selection-item {
  border-radius: 10px !important;
  line-height: 22px !important;
}

.ant-upload-drag .ant-upload {
  padding: 0px !important;
}

.ant-dropdown-menu {
  border-radius: 20px !important;
}

.ant-dropdown-menu-item {
  border-radius: 15px !important;
}

#filter-box .ant-dropdown-menu {
  box-shadow: none;
}

.ant-upload-select {
  border-radius: 20px !important;
}

.ant-upload-list-item {
  border-radius: 20px !important;
}

.ant-upload-list-item-thumbnail {
  border-radius: 15px !important;
}

.ant-upload-list-item-image:hover {
  border-radius: 15px !important;
}

#dropdownFilter .ant-dropdown-menu {
  box-shadow: none !important;
}

#popover-no-padding {
  padding: 0px !important;
}

#popover-no-padding .ant-menu-item {
  height: 32px !important;
  line-height: 32px !important;
  border-radius: 15px;
}

#popover-no-padding .ant-menu {
  padding-right: 25px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal-confirm .ant-modal-confirm-body {
  margin-top: 20px !important;
}

:where(.css-dev-only-do-not-override-zj8vpd).ant-picker-outlined {
  width: 80% !important;
  border-radius: 15px !important;
}

:where(.css-dev-only-do-not-override-zj8vpd).ant-select-single {
  width: 100%;
}