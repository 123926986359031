.topNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  font-size: 8px;
  color: white;
  position: absolute;
  right: -5px;
  top: 0px;
}

.customDropdownItem {
  display: flex;
  color: gray;
  align-items: center;
}

.customDropdownItem:hover {
  color: black;
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: -100px;
}

@media only screen and (max-width: 1000px) {
  .addEmployeeDashboardButton {
    display: none !important;
  }
}
